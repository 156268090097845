/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  background-color: white;
  /*background-image: url("img/Friends_of_Justin-hand_only.png");*/
}
.caifoj {
  text-align:center;
}
.footer {
  text-align: center;
}
img.caifoj {
  width:550px;
  margin-top:50px;

}
.edge {
  width:100%;
}
.content p {
  margin-left:20px;
  margin-right:20px;
}

/*!
styles kilroy
 */
.handprint {
  width:300px;
}
h1 {
  color:#a25138;
  font-weight:bold;
  font-size: 3em;
  text-align: center;
}

h2 {
  color:black;
  font-size: 2em;
  font-weight:bold;
}
h3 {
  color:black;
  font-weight:bold;
  font-size: 1.5em;
}


.content-hero img {
  margin-left:20px;
}
.headshot {
  margin: 0 20px 20px 20px;
  float:left;
}

.centeredImage {
  display: block; /* Treats the image as a block-level element */
  margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
  width: 100%; /* Optional: sets the width of the image */
}

@media screen and (min-width: 768px) {

  .centeredImage {
    display: block; /* Treats the image as a block-level element */
    margin: 0 auto; /* Sets the top and bottom margins to 0 and left and right margins to auto, centering the image */
    width: 100%; /* Optional: sets the width of the image */
  }
  h1.booktitle {
    font-weight:bold;
    font-size: 4em;
  }
.banner {
  width:60%;
  float:left;
  display: inline;
  text-align: center;
}
.Handprint img {
  width:200px;
}
  .content-hero {
    text-align:left;
    margin-left:0px;
    margin-right:0px;
  }
  .content-hero p {
    margin-left:20px;
    margin-right:20px;
  }
  h2, h3 {
    margin-left: 20px;
  }

  }
@media screen and (max-width: 768px) {
  .killdeer {
    width:300px;
  }
  h1.booktitle {
    font-size: 3em;
    margin-left:20px;
  }
  h2, h3 {
    font-weight: bold;
    text-align: center;
  }
  p {
    margin-left:20px;
  }

  .banner {
    width:100%;
    display: block;
    text-align: center;
  }
  .Handprint img {
    width:200px;
  }
  .parallax {
    display:none;
  }
  .leftImageFull {
    max-width: 300px;
  }
  img.caifoj {
    margin-top:50px;
    width:100%;
  }

  .caifoj {
    padding:0px;
    margin:0px;
    display:block;
  }
  .banner {
    width:100%;
    float:left;
    display: inline;
    text-align: center;
  }
}
@media screen and (min-width: 300px) {

}
/*below was already here*/
